// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrintDocs_adminContent__k0L6x {
  margin-top: 80px; /* Отступ сверху, чтобы панель не перекрывала */
  padding: 20px;
}

.PrintDocs_form__r7BrO {
  max-width: 600px; /* Ограничиваем максимальную ширину формы */
  margin: 0 auto; /* Выравнивание формы по центру */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PrintDocs_form-floating__YOPxU {
  margin-bottom: 15px;
}

.PrintDocs_form-select__AO9Ln, .PrintDocs_form-control__bJHVd {
  max-width: 100%;
}

.PrintDocs_submitButton__UgKqE {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.PrintDocs_submitButton__UgKqE:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/PrintDocs.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,+CAA+C;EACjE,aAAa;AACf;;AAEA;EACE,gBAAgB,EAAE,2CAA2C;EAC7D,cAAc,EAAE,iCAAiC;EACjD,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".adminContent {\r\n  margin-top: 80px; /* Отступ сверху, чтобы панель не перекрывала */\r\n  padding: 20px;\r\n}\r\n\r\n.form {\r\n  max-width: 600px; /* Ограничиваем максимальную ширину формы */\r\n  margin: 0 auto; /* Выравнивание формы по центру */\r\n  padding: 20px;\r\n  background-color: #f9f9f9;\r\n  border-radius: 8px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.form-floating {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.form-select, .form-control {\r\n  max-width: 100%;\r\n}\r\n\r\n.submitButton {\r\n  background-color: #007bff;\r\n  color: white;\r\n  padding: 10px 20px;\r\n  border: none;\r\n  border-radius: 5px;\r\n  width: 100%;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n}\r\n\r\n.submitButton:hover {\r\n  background-color: #0056b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminContent": `PrintDocs_adminContent__k0L6x`,
	"form": `PrintDocs_form__r7BrO`,
	"form-floating": `PrintDocs_form-floating__YOPxU`,
	"form-select": `PrintDocs_form-select__AO9Ln`,
	"form-control": `PrintDocs_form-control__bJHVd`,
	"submitButton": `PrintDocs_submitButton__UgKqE`
};
export default ___CSS_LOADER_EXPORT___;
