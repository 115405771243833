// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPage_menu_div__g0lMv {
  background-color: rgba(225, 225, 225, 0.2);
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: block;
  position: fixed; /* Keep the menu fixed at the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure menu stays above content */
}

.AdminPage_item__5oKjY {
  text-decoration: none;
  margin: 1.5rem;
  color: rgb(151, 1, 14);
  font-family: "Ysabeau SC";
}

.AdminPage_active__XZtAh {
  color: grey;
  padding-left: 1.5rem;
  text-decoration: none;
  font-weight: bold;
}

.AdminPage_admin-content__C0iCA {
  margin-top: 100px; /* Increase the top margin to prevent content from being hidden behind the menu */
  padding: 20px;
}

/* Additional responsive styles */
@media screen and (max-width: 768px) {
  .AdminPage_menu_div__g0lMv {
    flex-direction: column;
    gap: 1rem;
  }

  .AdminPage_form-floating__mwYoS {
    width: 100%;
  }

  .AdminPage_form-control__blah3,
  input[type="submit"] {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/AdminPage.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,cAAc;EACd,eAAe,EAAE,mCAAmC;EACpD,MAAM;EACN,OAAO;EACP,aAAa,EAAE,oCAAoC;AACrD;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB,EAAE,iFAAiF;EACpG,aAAa;AACf;;AAEA,iCAAiC;AACjC;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,WAAW;EACb;;EAEA;;IAEE,WAAW;EACb;AACF","sourcesContent":[".menu_div {\r\n  background-color: rgba(225, 225, 225, 0.2);\r\n  text-align: center;\r\n  padding: 1rem;\r\n  width: 100%;\r\n  display: block;\r\n  position: fixed; /* Keep the menu fixed at the top */\r\n  top: 0;\r\n  left: 0;\r\n  z-index: 1000; /* Ensure menu stays above content */\r\n}\r\n\r\n.item {\r\n  text-decoration: none;\r\n  margin: 1.5rem;\r\n  color: rgb(151, 1, 14);\r\n  font-family: \"Ysabeau SC\";\r\n}\r\n\r\n.active {\r\n  color: grey;\r\n  padding-left: 1.5rem;\r\n  text-decoration: none;\r\n  font-weight: bold;\r\n}\r\n\r\n.admin-content {\r\n  margin-top: 100px; /* Increase the top margin to prevent content from being hidden behind the menu */\r\n  padding: 20px;\r\n}\r\n\r\n/* Additional responsive styles */\r\n@media screen and (max-width: 768px) {\r\n  .menu_div {\r\n    flex-direction: column;\r\n    gap: 1rem;\r\n  }\r\n\r\n  .form-floating {\r\n    width: 100%;\r\n  }\r\n\r\n  .form-control,\r\n  input[type=\"submit\"] {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_div": `AdminPage_menu_div__g0lMv`,
	"item": `AdminPage_item__5oKjY`,
	"active": `AdminPage_active__XZtAh`,
	"admin-content": `AdminPage_admin-content__C0iCA`,
	"form-floating": `AdminPage_form-floating__mwYoS`,
	"form-control": `AdminPage_form-control__blah3`
};
export default ___CSS_LOADER_EXPORT___;
