import React from "react";
import data from "../../data/ContactsData.json";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import s from "../DocumentsPage/DocumentsPage.module.css";
const Contacts = () => {
  return (
    <>
      <div className={s.container}>
        <h1 className={s.title}>Связаться с нами</h1>

        <p className={s.link}>
          <FaEnvelope className={s.icon} />
          e-mail:{data.email}
        </p>

        <p className={s.link}>
          <FaPhoneAlt className={s.icon} />
          Телефон:{data.phone}
        </p>
      </div>
    </>
  );
};
export default Contacts;
