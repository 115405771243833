import React from "react";
import s from "./Presentation.module.css";
const PresentationElement = (props) => {
  return (
    <div className={s.element}>
      <img className={s.elementImg} src={props.img} />
      <div className={s.description}>
        <p className={s.name}>{props.name}</p>
        <p>{props.text}</p>
      </div>
    </div>
  );
};
export default PresentationElement;
