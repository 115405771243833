import React, { useEffect } from "react";
import AddEvent from "./AddEvent";
import GetUserEvents from "./GetUserEvents";
import Event from "./Event";
import AdminPage from "../AdminPage";

const AllEvents = (props) => {
  const getEvents = async () => {
    try {
      const rawResponse = await fetch(`/api/events`, {
        method: "GET",
      });
      const data = await rawResponse.json();
      props.setEvents(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  let events = props.events.map((item) => (
      <Event eventName={item.eventName} description={item.description} />
  ));

  return (
      <>
        <AdminPage />
        <div className="admin-content">
          {events}
          <AddEvent addEvent={props.addEvent} />
          <br />
          <GetUserEvents
              pioneerId={props.pioneerId}
              events={props.events}
              userEvents={props.userEvents}
              setUserEvents={props.setUserEvents}
              setPioneerId={props.setPioneerId}
              addUserEvent={props.addUserEvent}
          />
        </div>
      </>
  );
};

export default AllEvents;
