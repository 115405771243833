import React from "react";
import data from "../../../data/Presentation.json";
import PresentationElement from "./PresentationElement";
import s from "./Presentation.module.css";
const Presentation = () => {
  let tmp = data.elements.map((item) => (
    <PresentationElement name={item.name} img={item.img} text={item.text} />
  ));
  return (
    <div className={s.container}>
      <div className={s.valueSection}>
        <p className={s.name}>{data.title}</p>
        <p className={s.description}>{data.valueOfPioneering}</p>
      </div>
      {tmp}
    </div>
  );
};
export default Presentation;
