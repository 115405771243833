import React from "react";
import { useForm } from "react-hook-form";

const AddEvent = (props) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    (async () => {
      const rawResponse = await fetch("/api/events", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const content = await rawResponse.json();
      props.addEvent(content);
    })();
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-floating mb-3">
          <input
              type="text"
              className="form-control"
              placeholder="Название события"
              id="eventName"
              {...register("eventName", { required: true })}
          />
          <label htmlFor="eventName">Название события</label>
        </div>
        <div className="form-floating mb-3">
          <input
              type="text"
              className="form-control"
              placeholder="Описание события"
              id="description"
              {...register("description", { required: true })}
          />
          <label htmlFor="description">Описание события</label>
        </div>
        <input type="submit" value="Добавить событие" />
      </form>
  );
};

export default AddEvent;
