// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/fonts/SovMod.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'SovMod';
    src: local('SovMod'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('ttf');
  }
.Presentation_container__jD48g
{   
    padding-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    position: relative;
}
.Presentation_valueSection__L2CAq
{   
    width: 35rem;
    text-align: center;
}
.Presentation_upperElement__le8bP
{   
    text-align: center;
}
.Presentation_valueOfPioneering__GGnxV
{
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3rem;
    padding: 0.1rem;
}
.Presentation_element__jMNLP
{
    display:flex;
    width:32rem;
    padding: 0.3rem;
    
}
.Presentation_name__NrneT
{
    font-size: 1.5rem;
    font-family: 'Ysabeau SC';
    font-weight: bold;
    color:rgb(203, 18, 18);
}
.Presentation_title__NuZ7B
{
    text-transform: uppercase;
    font-family: 'sovmod';
    font-size: 2rem;
    color: rgb(203, 18, 18);
}
.Presentation_elementImg__7LDzR
{
    width: 6rem;
    margin: 1rem;
}
.Presentation_description__p43YY
{
    display:block
}`, "",{"version":3,"sources":["webpack://./src/components/mainPage/Presentation/Presentation.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2EAAgE;EAClE;AACF;;IAEI,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,cAAc;IACd,uBAAuB;IACvB,kBAAkB;AACtB;AACA;;IAEI,YAAY;IACZ,kBAAkB;AACtB;AACA;;IAEI,kBAAkB;AACtB;AACA;;IAEI,4CAA4C;IAC5C,iBAAiB;IACjB,eAAe;AACnB;AACA;;IAEI,YAAY;IACZ,WAAW;IACX,eAAe;;AAEnB;AACA;;IAEI,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;;IAEI,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,uBAAuB;AAC3B;AACA;;IAEI,WAAW;IACX,YAAY;AAChB;AACA;;IAEI;AACJ","sourcesContent":["@font-face {\r\n    font-family: 'SovMod';\r\n    src: local('SovMod'), url('/src/fonts/SovMod.ttf') format('ttf');\r\n  }\r\n.container\r\n{   \r\n    padding-top: 5rem;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin: 0 auto;\r\n    justify-content: center;\r\n    position: relative;\r\n}\r\n.valueSection\r\n{   \r\n    width: 35rem;\r\n    text-align: center;\r\n}\r\n.upperElement\r\n{   \r\n    text-align: center;\r\n}\r\n.valueOfPioneering\r\n{\r\n    font-family: 'Times New Roman', Times, serif;\r\n    font-size: 1.3rem;\r\n    padding: 0.1rem;\r\n}\r\n.element\r\n{\r\n    display:flex;\r\n    width:32rem;\r\n    padding: 0.3rem;\r\n    \r\n}\r\n.name\r\n{\r\n    font-size: 1.5rem;\r\n    font-family: 'Ysabeau SC';\r\n    font-weight: bold;\r\n    color:rgb(203, 18, 18);\r\n}\r\n.title\r\n{\r\n    text-transform: uppercase;\r\n    font-family: 'sovmod';\r\n    font-size: 2rem;\r\n    color: rgb(203, 18, 18);\r\n}\r\n.elementImg\r\n{\r\n    width: 6rem;\r\n    margin: 1rem;\r\n}\r\n.description\r\n{\r\n    display:block\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Presentation_container__jD48g`,
	"valueSection": `Presentation_valueSection__L2CAq`,
	"upperElement": `Presentation_upperElement__le8bP`,
	"valueOfPioneering": `Presentation_valueOfPioneering__GGnxV`,
	"element": `Presentation_element__jMNLP`,
	"name": `Presentation_name__NrneT`,
	"title": `Presentation_title__NuZ7B`,
	"elementImg": `Presentation_elementImg__7LDzR`,
	"description": `Presentation_description__p43YY`
};
export default ___CSS_LOADER_EXPORT___;
