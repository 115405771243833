import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header/Header";
import MissionSection from "./MissionSection/MissionSection";
import NewsPreviewContainer from "./NewsPreview/NewsPreviewContainer";
import FooterGallery from "./FooterGallery/footerGallery";
import styles from "./MainPage.module.scss";

const MainPage = () => {
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Проверяем, находимся ли мы близко к нижней части страницы
      setAtBottom(scrollTop + clientHeight >= scrollHeight - 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Header />
      <MissionSection />
      <NewsPreviewContainer />
      <FooterGallery />
      <button
        className={`${styles.joinButton} ${atBottom ? styles.moveUp : ""}`}
        onClick={() => alert("Переход на страницу регистрации")}
      >
        Вступить в организацию
      </button>
    </>
  );
};

export default MainPage;
