import React from "react";
import { NavLink } from "react-router-dom";
import s from "./AdminPage.module.css";

const AdminPage = () => {
  return (
      <div className={s.menu_div}>
        <NavLink
            to="/admin/print-docs"
            className={(navData) => (navData.isActive ? s.active : s.item)}
        >
          Печать документов
        </NavLink>
        <NavLink
            to="/admin/events"
            className={(navData) => (navData.isActive ? s.active : s.item)}
        >
          Cобытия
        </NavLink>
        <NavLink
            to="/admin/users"
            className={(navData) => (navData.isActive ? s.active : s.item)}
        >
          Пользователи
        </NavLink>
        {/* Дополнительные кнопки можно добавить сюда */}
      </div>
  );
};

export default AdminPage;
