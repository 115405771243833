import React, { useState, useEffect } from "react";
import s from "./AdminBooks.module.css";

const AdminBooks = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editingBook, setEditingBook] = useState(null); // Current book for editing
  const [formValues, setFormValues] = useState({
    name1: "",
    author: "",
    year: "",
    annot: "",
  });
  const [searchQuery, setSearchQuery] = useState(""); // Search query

  useEffect(() => {
    fetchBooks();
  }, [searchQuery]); // Refetch books when searchQuery changes

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const endpoint = searchQuery
          ? `/api/books/search?search=${searchQuery}`
          : `/api/books`;
      const response = await fetch(endpoint);
      const data = await response.json();
      setBooks(data.content || []);
    } catch (error) {
      console.error("Error fetching books:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`/api/books/${id}`, { method: "DELETE" });
      setBooks(books.filter((book) => book.id !== id));
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  const handleOpenModal = (book = null) => {
    setEditingBook(book);
    setFormValues({
      name1: book?.name1 || "",
      author: book?.author || "",
      year: book?.year || "",
      annot: book?.annot || "",
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingBook(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = editingBook ? "PUT" : "POST";
      const url = editingBook
          ? `/api/books/${editingBook.id}`
          : "/api/books";
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      const newBook = await response.json();
      if (editingBook) {
        setBooks(books.map((book) => (book.id === newBook.id ? newBook : book)));
      } else {
        setBooks((prev) => [...prev, newBook]);
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error saving book:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
      <div className={s.admin_books}>
        <h1>Управление книгами</h1>
        <input
            type="text"
            placeholder="Поиск книг..."
            className={s.search_input}
            value={searchQuery}
            onChange={handleSearch}
        />
        <button className={s.add_button} onClick={() => handleOpenModal()}>
          Добавить книгу
        </button>
        {loading ? (
            <div>Загрузка...</div>
        ) : (
            <table className={s.books_table}>
              <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Автор</th>
                <th>Год</th>
                <th>Действия</th>
              </tr>
              </thead>
              <tbody>
              {books.map((book) => (
                  <tr key={book.id}>
                    <td>{book.id}</td>
                    <td>{book.name1}</td>
                    <td>{book.author || "Не указано"}</td>
                    <td>{book.year || "Не указано"}</td>
                    <td>
                      <button onClick={() => handleOpenModal(book)}>Редактировать</button>
                      <button onClick={() => handleDelete(book.id)}>Удалить</button>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
        )}

        {showModal && (
            <div className={s.modal}>
              <div className={s.modal_content}>
                <h2>{editingBook ? "Редактировать книгу" : "Добавить книгу"}</h2>
                <form onSubmit={handleSubmit}>
                  <label>Название</label>
                  <input
                      type="text"
                      name="name1"
                      value={formValues.name1}
                      onChange={handleChange}
                      placeholder="Введите название книги"
                      required
                  />
                  <label>Автор</label>
                  <input
                      type="text"
                      name="author"
                      value={formValues.author}
                      onChange={handleChange}
                      placeholder="Введите имя автора"
                  />
                  <label>Год</label>
                  <input
                      type="text"
                      name="year"
                      value={formValues.year}
                      onChange={handleChange}
                      placeholder="Введите год издания"
                  />
                  <label>Аннотация</label>
                  <textarea
                      name="annot"
                      value={formValues.annot}
                      onChange={handleChange}
                      placeholder="Введите аннотацию книги"
                  />
                  <div className={s.modal_actions}>
                    <button type="submit">Сохранить</button>
                    <button type="button" className={s.cancel_button} onClick={handleCloseModal}>
                      Отмена
                    </button>
                  </div>
                </form>
              </div>
            </div>
        )}
      </div>
  );
};

export default AdminBooks;
